.CodeMirror {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.CodeMirror-sizer {
  cursor: text;
  height: 100%;
  margin-bottom: 0 !important;
  padding-bottom: 16px !important;
}
