.page-admin {
  .system-statistics {
    .cpu-usage-card {
      flex: 1 1 0;
    }

    .storage-card {
      flex: 1.5 0 0;

      .progress {
        margin-bottom: 5px;

        .progress-bar {
          border-bottom-width: 20px;

          &::after {
            height: 20px;
          }
        }
      }
    }
  }
}
