#modal-log-view {
  #modal-log-view-contents {
    font-family: $font-family-monospace;
    font-size: $font-size-caption;
    min-height: 300px;
    max-height: 500px;
    overflow-x: auto;
    overflow-y: scroll;
  }
}
