// Dark and light theme
// Based on https://material.google.com/style/color.html#color-themes

@include bg-variant('.bg-dark-1', $dark-theme-1);
@include bg-variant('.bg-dark-2', $dark-theme-2);
@include bg-variant('.bg-dark-3', $dark-theme-3);
@include bg-variant('.bg-dark-4', $dark-theme-4);
@include bg-variant('.bg-light-1', $light-theme-1);
@include bg-variant('.bg-light-2', $light-theme-2);
@include bg-variant('.bg-light-3', $light-theme-3);
@include bg-variant('.bg-light-4', $light-theme-4);
@include bg-variant('.bg-transparent', transparent);
@include bg-variant('.bg-white', $white);

// Palette

@each $color, $values in $theme-colors {
  @include bg-variant('.bg-#{$color}', theme-color($color), theme-color-dark($color));
}

@include bg-variant('.bg-primary-dark', theme-color-dark(primary));
@include bg-variant('.bg-primary-light', theme-color-light(primary));
@include bg-variant('.bg-secondary-dark', theme-color-dark(secondary));
@include bg-variant('.bg-secondary-light', theme-color-light(secondary));
